import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import ItemsList from '@shared/ui/display/ItemsList';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer } from '@shared/ui/sidebars/shared';
import { ReactComponent as ClosePortfolioIcon } from '@icons/custom/close-portfolio.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-solid/alert-triangle-solid.svg';
import Icon from '@shared/ui/icons/Icon';
import Button from '@shared/ui/buttons/Button';
import { BodyLarge, BodySemiBold } from '@src/components/styled/Typography';
import { ContainerRow } from '@src/components/styled';
import { useAppSelector } from '@app/store/Hooks';
import { closeSidebar } from '@entities/exchange';
const Title = styled(BodyLarge)(() => ({
    fontSize: 24,
    letterSpacing: '-0.456px',
    lineHeight: '36px',
}));
const PortfolioTitle = styled.span(() => ({
    fontWeight: 600,
}));
const WarningContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    background: '#F8F9FA',
    padding: props.theme.spacing_sizes.xm,
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
    boxSizing: 'border-box',
    borderRadius: 6,
}));
const WarningTextContainer = styled.div();
const WarningTitle = styled(BodySemiBold)(props => ({
    fontWeight: 600,
    letterSpacing: '-0.084px',
    marginBottom: props.theme.spacing_sizes.xs * 0.25,
}));
const ClosePortfolioSidebar = () => {
    const theme = useTheme();
    const { selectedPortfolio } = useAppSelector(state => state.exchange);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(closeSidebar());
    };
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepContainer, { title: (_jsx(Title, { children: Trans({
                    i18nKey: 'overview.close_portfolio.title',
                    values: {
                        portfolioName: selectedPortfolio === null || selectedPortfolio === void 0 ? void 0 : selectedPortfolio.name,
                    },
                    components: [_jsx(PortfolioTitle, {}, 'portfolio-name')],
                }) })), content: (_jsxs(_Fragment, { children: [_jsx(Icon, { IconComponent: ClosePortfolioIcon, size: 200, keepOriginalColor: true }), _jsxs(WarningContainer, { children: [_jsx(Icon, { IconComponent: AlertIcon, size: 24, color: theme.palette.warning.main }), _jsxs(WarningTextContainer, { children: [_jsx(WarningTitle, { children: t('overview.close_portfolio.warning.title') }), _jsx(BodySemiBold, { children: t('overview.close_portfolio.warning.text') })] })] }), _jsx(ItemsList, { list: t('overview.close_portfolio.description_items', { returnObjects: true }) })] })), contentJustify: 'space-around', contentFlex: 1, buttons: (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleClose, size: 'large', fullWidth: true, children: t('overview.close_portfolio.continue_btn') }), _jsx(Button, { variant: 'tinted', size: 'large', onClick: handleClose, fullWidth: true, children: t('overview.close_portfolio.cancel_btn') })] })), buttonsGap: theme.spacing_sizes.m }) }));
};
export default ClosePortfolioSidebar;
